import { useRouter } from 'next/router'

export const Countries = ['de', 'at', 'ch'] as const
export type Country = (typeof Countries)[number]

export const Stations = [
  'rtl',
  'rtluhd',
  'freenetrtl',
  'nitro',
  'rtlplus',
  'vox',
  'voxup',
  'freenetvox',
] as const
export type Station = (typeof Stations)[number]

export interface BroadcastInfo {
  station: Station
  country: Country
}

export const useBroadcastInfo = (): BroadcastInfo => {
  const router = useRouter()
  const {
    query: { country, station },
    asPath,
  } = router

  if (country && station) {
    return {
      country: country as Country,
      station: station as Station,
    }
  }

  // fallback to url eg. for 404 because there is no router query object present
  const routeParts = asPath.split('/').filter(Boolean)
  const urlStation = Stations.find((x) => x === routeParts[0]) ?? 'rtl'
  const urlCountry = Countries.find((x) => x === routeParts[1]) ?? 'de'
  return {
    country: urlCountry,
    station: urlStation,
  }
}

export function isValidBroadcastInfo({
  country,
  station,
}: {
  country: string | null | undefined
  station: string | null | undefined
}): boolean {
  return arrayIncludes(Stations, station) && arrayIncludes(Countries, country)
}

function arrayIncludes<T>(values: readonly T[], x: any): x is T {
  return values.includes(x)
}
