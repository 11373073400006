import { Metadata, TrackingConfiguration } from './usePageTracking'
import { Station } from '@lib/hooks/useBroadcastInfo'
import { getConsent } from '@lib/utils/consent'
import { getSmartnsIdFromCookie } from '@lib/utils/device'

type TracktorEventType = 'view' | 'click' | 'media'

const trackTracktor = (
  metadata: Metadata,
  trackingConfig: TrackingConfiguration,
  station: Station,
  eventType: TracktorEventType,
  videoId?: string,
) => {
  const { agof, ivw } = metadata
  const { offer } = trackingConfig

  let st = offer[station]
  if (!st) {
    st = 'ctvrtlde'
    console.error(
      `No offer for station "${station}" found (check NEXT_PUBLIC_TRACKING_CONFIGURATION), ` +
        `using fallback value "${st}".`,
    )
  }

  const trackerParameter = {
    timestamp: new Date(),
    status_code: 'ok',
    id: getSmartnsIdFromCookie(),
    tags: {
      ev: '',
      st,
      cp: agof,
      co: ivw,
      cs: '',
      local_time: new Date(),
    },
    user_agent: navigator.userAgent,
    measure_type: '',
    locallist_check: '',
    useragent_whitelist_check: '',
  }

  if (typeof window !== 'undefined') {
    // @ts-expect-error window._itracker2 is not defined
    const iTracker2 = window._itracker2 || {}
    iTracker2.consent = getConsent()
    switch (eventType) {
      case 'view':
        iTracker2.logViewEvent && iTracker2.logViewEvent(ivw, trackerParameter)
        break
      case 'click':
        iTracker2.logClickEvent &&
          iTracker2.logClickEvent(ivw, trackerParameter)
        break
      case 'media':
        iTracker2.logMediaEvent &&
          iTracker2.logMediaEvent('play', 0, videoId, trackerParameter)
        break
      default:
        console.error('Unknown Tracktor tracking event type')
        return
    }

    try {
      iTracker2.transmitNow && iTracker2.transmitNow()
    } catch (error) {
      console.error('Failed to track Tracktor')
    }
  } else {
    console.warn('Tracktor is not loaded or iTracker2 is not available')
  }
}

export default trackTracktor
