import { CSSProperties } from 'styled-components'
import { BROADCAST_ELEMENT_ID } from './OipfApplication/broadcast'

export const broadcastObjectStyle: CSSProperties = {
  position: 'absolute',
  outline: 'transparent',
  top: '355px',
  left: '63px',
  width: '248px',
  height: '139px',
  visibility: 'hidden',
  zIndex: 1000,
}

export default function OipfApplicationContainer() {
  // Important: We need the layering of the oipfApplicationContainer, content and menu for
  // Samsung 2016 and similar TVs, else the TV signal will be overlayed by the elements or it will overlay the menu
  // Do not use display: none; as the Samsung 2023 seems to "optimise" the elements and not load them.
  return (
    <div
      id="oipfApplicationContainer"
      style={{
        position: 'absolute',
        zIndex: 900,
        width: '1280px',
        height: '720px',
        pointerEvents: 'none',
      }}
    >
      <object
        id="appmgr"
        type="application/oipfApplicationManager"
        style={{
          position: 'absolute',
          width: 0,
          height: 0,
          left: 0,
          top: 0,
        }}
      />
      <object
        id="oipfcfg"
        type="application/oipfConfiguration"
        style={{
          position: 'absolute',
          width: 0,
          height: 0,
          left: 0,
          top: 0,
        }}
      />
      <div
        style={{
          zIndex: 990,
          left: 0,
          top: 0,
          width: '1280px',
          height: '720px',
          margin: '0px',
          pointerEvents: 'none',
        }}
      >
        <object
          id={BROADCAST_ELEMENT_ID}
          type="video/broadcast"
          style={broadcastObjectStyle}
          width="248"
          height="139"
        />
      </div>
      {/*
      Samsung 2023 does not seem to get the owner application if the function is executed too late.
      The script must therefore be placed in the initial html.
      */}
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
//<![CDATA[
  window.onload = function () {
    try {
      var app = document.getElementById("appmgr").getOwnerApplication(document)
      app.show && app.show()
      app.activate && app.activate()
    } catch(e) {
      console.log('oipf register failed', e)
    }
  }
//]]>`,
        }}
      ></script>
    </div>
  )
}
