import Cookies, { CookieSetOptions } from 'universal-cookie'
import getConfig from 'next/config'
import { bugsnag } from '@lib/bugsnag'

const { publicRuntimeConfig } = getConfig()

const cookies = new Cookies()
const localStorageExpirationKey = (key: string) => `${key}__expires`

const getFromLocalStorage = (
  name: string,
  parseValue: boolean,
): string | any | void => {
  try {
    if (localStorage) {
      const itemFromLocalStorage = localStorage.getItem(name)
      const expirationDate = localStorage.getItem(
        localStorageExpirationKey(name),
      )

      if (expirationDate && new Date(expirationDate).getTime() < Date.now()) {
        return undefined
      }

      if (itemFromLocalStorage !== null) {
        return parseValue
          ? JSON.parse(itemFromLocalStorage)
          : itemFromLocalStorage
      }
    }
  } catch (error) {
    bugsnag?.notify(error)
  }
}

export function getItem(name: string, parseValue = true): string | any {
  const value = cookies.get(name, { doNotParse: !parseValue })
  if (!value) {
    return getFromLocalStorage(name, parseValue)
  }

  return value
}

const setCookie = (name: string, value: any, options: CookieSetOptions) => {
  try {
    cookies.set(name, value, options)
  } catch (error) {
    bugsnag?.notify(error)
  }
}

const setLocalStorage = (
  name: string,
  value: any,
  {
    expires,
    maxAge,
  }: { expires?: Date | string | number; maxAge?: number } = {},
) => {
  try {
    if (localStorage) {
      localStorage.setItem(name, JSON.stringify(value))
      if (maxAge) {
        localStorage.setItem(
          localStorageExpirationKey(name),
          new Date(Date.now() + maxAge * 1000).toISOString(),
        )
      } else if (expires) {
        localStorage.setItem(
          localStorageExpirationKey(name),
          new Date(expires).toISOString(),
        )
      } else {
        localStorage.removeItem(localStorageExpirationKey(name))
      }
    }
  } catch (error) {
    bugsnag?.notify(error)
  }
}

export function setItem(
  name: string,
  value: any,
  cookieOptions: CookieSetOptions = {},
) {
  setCookie(name, value, {
    domain: publicRuntimeConfig.processEnv.NEXT_PUBLIC_STORAGE_COOKIE_DOMAIN,
    ...cookieOptions,
    expires:
      cookieOptions.expires ||
      new Date(Date.now() + 25 * 365 * 24 * 60 * 60 * 1000),
  })
  setLocalStorage(name, value, cookieOptions)

  return value
}

function removeCookie(name: string) {
  try {
    cookies.remove(name, {
      domain: publicRuntimeConfig.processEnv.NEXT_PUBLIC_STORAGE_COOKIE_DOMAIN,
    })
  } catch (error) {
    bugsnag?.notify(error)
  }
}

function removeLocalStorage(name: string) {
  try {
    if (localStorage) {
      localStorage.removeItem(name)
      localStorage.removeItem(localStorageExpirationKey(name))
    }
  } catch (error) {
    bugsnag?.notify(error)
  }
}

export function removeItem(name: string) {
  removeCookie(name)
  removeLocalStorage(name)
}

/**
 * Do NOT call this from production code!
 */
export function __clearCookiesOnlyKeepLocalStorageUnitTestsOnly() {
  for (const key in cookies.getAll()) {
    cookies.remove(key)
  }
}
